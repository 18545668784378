<template>
  <div class="page-content-password">
    <div class="content">
      <v-form ref="formPassword" class="form-update-info-person">
        <div class="profile-pic mb-3 pb-3">
          <label class="-label" for="file">
            <font-awesome-icon class="img-h-19" icon="camera" />
            <span class="mt-1">Change Image</span>
          </label>
          <input id="file" type="file" @change="loadFile" />
          <img src="@/assets/img/avatar-ila.png" id="output" width="200" />
          <img
            v-if="currentUser && currentUser.logo"
            :src="currentUser.logo"
            id="output"
            width="200"
          />
        </div>
        <v-row>
          <v-col>
            <v-text-field
              v-model="ChangePassword.Nom"
              outlined
              dense
              :persistent-placeholder="true"
              :label="
                $i18n.locale === 'fr' ? 'Nom & Prénom' : 'Name & First name'
              "
              disabled
              color="#5C2DD3"
            ></v-text-field
          ></v-col>
          <v-col>
            <v-text-field
              v-model="ChangePassword.Email"
              outlined
              dense
              :persistent-placeholder="true"
              label="E-mail"
              disabled
              color="#5C2DD3"
            ></v-text-field
          ></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              :label="
                $i18n.locale === 'fr' ? 'Nouveau mot de passe' : 'New password'
              "
              type="password"
              v-model="ChangePassword.password"
              outlined
              dense
              :persistent-placeholder="true"
              required
              validate-on-blur
              :rules="[
                v =>
                  !!v ||
                  $t('msgObligMsg', {
                    msg:
                      $i18n.locale === 'fr'
                        ? 'Nouveau mot de passe'
                        : 'New password'
                  })
              ]"
              color="#5C2DD3"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :label="$t('confirm_password')"
              type="password"
              v-model="ChangePassword.password_confirmation"
              outlined
              dense
              :persistent-placeholder="true"
              required
              validate-on-blur
              :rules="[
                v => !!v || $t('msgObligMsg', { msg: $t('confirm_password') })
              ]"
              color="#5C2DD3"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <div class="message">
              <div v-if="loading" class="loading">
                {{ $t('loading') }}
              </div>
              <div v-if="updated" class="success-msg">
                {{
                  $i18n.locale === 'fr'
                    ? 'Le mot de passe est modifié'
                    : 'Password changed'
                }}
              </div>
              <div v-if="error" class="error-msg">
                <ul v-if="Array.isArray(error)">
                  <li v-for="(e, index) in error" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <span v-else>{{ error }}</span>
              </div>
            </div>
          </v-col>
          <v-col>
            <v-btn
              class="float-right"
              color="#5C2DD3"
              dark
              @click.prevent="handleSubmit"
              :loading="loading"
            >
              {{ $t('btnValider') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      ChangePassword: {
        Nom: null,
        Email: null,
        password: null,
        password_confirmation: null
      },
      loading: false,
      error: null,
      updated: false,
      image: null
    }
  },
  methods: {
    ...mapActions(['updatePassword']),
    loadFile(event) {
      this.image = document.getElementById('output')
      this.image.src = URL.createObjectURL(event.target.files[0])
    },
    async handleSubmit() {
      if (this.$refs.formPassword.validate()) {
        this.loading = true
        this.updated = false
        this.error = null
        if (this.ChangePassword.password) {
          if (
            this.ChangePassword.password !=
            this.ChangePassword.password_confirmation
          ) {
            this.loading = false
            this.error = [
              {
                password:
                  this.$i18n.locale === 'fr'
                    ? 'le mot de passe et la confirmation du mot de passe doivent être égaux'
                    : 'password and password confirmation must be equal'
              }
            ]
            return
          }
          if (this.ChangePassword.password.length < 6) {
            this.loading = false
            this.error = [
              {
                password:
                  this.$i18n.locale === 'fr'
                    ? 'le mot de passe doit contenir au moins 6 caractères'
                    : 'password must contain at least 6 characters'
              }
            ]
            return
          }
        }
        const response = await this.updatePassword(this.ChangePassword)
        if (response.success) {
          this.loading = false
          this.updated = true
        } else {
          this.loading = false
          this.error = response.error
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  async mounted() {
    this.ChangePassword.Nom =
      this.currentUser.first_name + ' ' + this.currentUser.last_name
    this.ChangePassword.Email = this.currentUser.email
    this.ChangePassword.id = this.currentUser.id
  }
}
</script>

<style lang="scss" scoped>
.page-content-password {
  padding: 0px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .content {
    // background-color: #f8f8f8;
    padding: 10px;
    flex-grow: 1;
    .form-update-info-person {
      width: 60% !important;
      margin: auto;
      @mixin object-center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      $circleSize: 165px;
      $radius: 100px;
      $shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
      $fontColor: rgb(250, 250, 250);

      .profile-pic {
        color: transparent;
        transition: all 0.3s ease;
        @include object-center;
        position: relative;
        transition: all 0.3s ease;
        z-index: 1;
        input {
          display: none;
        }

        img {
          position: absolute;
          object-fit: cover;
          width: $circleSize;
          height: $circleSize;
          box-shadow: $shadow;
          border-radius: $radius;
          z-index: 0;
        }

        .-label {
          cursor: pointer;
          height: $circleSize;
          width: $circleSize;
        }

        &:hover {
          .-label {
            @include object-center;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 10000;
            color: $fontColor;
            transition: background-color 0.2s ease-in-out;
            border-radius: $radius;
            margin-bottom: 0;
          }
        }

        span {
          display: inline-flex;
          padding: 0.2em;
          height: 2em;
        }
      }
    }
  }
}
</style>
