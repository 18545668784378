var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content-password"},[_c('div',{staticClass:"content"},[_c('v-form',{ref:"formPassword",staticClass:"form-update-info-person"},[_c('div',{staticClass:"profile-pic mb-3 pb-3"},[_c('label',{staticClass:"-label",attrs:{"for":"file"}},[_c('font-awesome-icon',{staticClass:"img-h-19",attrs:{"icon":"camera"}}),_c('span',{staticClass:"mt-1"},[_vm._v("Change Image")])],1),_c('input',{attrs:{"id":"file","type":"file"},on:{"change":_vm.loadFile}}),_c('img',{attrs:{"src":require("@/assets/img/avatar-ila.png"),"id":"output","width":"200"}}),(_vm.currentUser && _vm.currentUser.logo)?_c('img',{attrs:{"src":_vm.currentUser.logo,"id":"output","width":"200"}}):_vm._e()]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","persistent-placeholder":true,"label":_vm.$i18n.locale === 'fr' ? 'Nom & Prénom' : 'Name & First name',"disabled":"","color":"#5C2DD3"},model:{value:(_vm.ChangePassword.Nom),callback:function ($$v) {_vm.$set(_vm.ChangePassword, "Nom", $$v)},expression:"ChangePassword.Nom"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","persistent-placeholder":true,"label":"E-mail","disabled":"","color":"#5C2DD3"},model:{value:(_vm.ChangePassword.Email),callback:function ($$v) {_vm.$set(_vm.ChangePassword, "Email", $$v)},expression:"ChangePassword.Email"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Nouveau mot de passe' : 'New password',"type":"password","outlined":"","dense":"","persistent-placeholder":true,"required":"","validate-on-blur":"","rules":[
              v =>
                !!v ||
                _vm.$t('msgObligMsg', {
                  msg:
                    _vm.$i18n.locale === 'fr'
                      ? 'Nouveau mot de passe'
                      : 'New password'
                })
            ],"color":"#5C2DD3"},model:{value:(_vm.ChangePassword.password),callback:function ($$v) {_vm.$set(_vm.ChangePassword, "password", $$v)},expression:"ChangePassword.password"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('confirm_password'),"type":"password","outlined":"","dense":"","persistent-placeholder":true,"required":"","validate-on-blur":"","rules":[
              v => !!v || _vm.$t('msgObligMsg', { msg: _vm.$t('confirm_password') })
            ],"color":"#5C2DD3"},model:{value:(_vm.ChangePassword.password_confirmation),callback:function ($$v) {_vm.$set(_vm.ChangePassword, "password_confirmation", $$v)},expression:"ChangePassword.password_confirmation"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"message"},[(_vm.loading)?_c('div',{staticClass:"loading"},[_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")]):_vm._e(),(_vm.updated)?_c('div',{staticClass:"success-msg"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Le mot de passe est modifié' : 'Password changed')+" ")]):_vm._e(),(_vm.error)?_c('div',{staticClass:"error-msg"},[(Array.isArray(_vm.error))?_c('ul',_vm._l((_vm.error),function(e,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.error))])]):_vm._e()])]),_c('v-col',[_c('v-btn',{staticClass:"float-right",attrs:{"color":"#5C2DD3","dark":"","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('btnValider')))])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }